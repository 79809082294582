export const videos = [
  "IrvuJ9s95ZI",
  "ADXC5-8YKgY",
  "HNbkzyBWurA",
  "KvMf6u3kDnk",
  "zLQgj2ZmNfU",
  "FMRpYDSltzM",
  "cBX5dPJJUI0",
  "hkhJ2pPWaCw",
  "Sn15hIZk8M8",
  "GvoIU9_PzuQ",
  "24fOg_WKSCA",
  "wjbOsqOS7vI",
  "ljmyrUHmLxQ",
  "ipeTBI-cnVo",
  "AP28hBBkIWQ",
  "J_pwdlGZNjk",
  "o6ZAKk0Y_xQ",
  "2fI01WGVZ4Y",
  "mc7d5MdGjM4",
  "8BRo2teNG90",
  "2S8yeI0KYyo",
  "axa1g5xPku4",
  "LTQNLbuw4CY",
  "0CtMtstSIVY",
  "1xGPn9yyZzI",
  "IexA-W-YuwU",
  "SRb5g8yPr2A",
  "i27gR4Ohp04",
  "z1yRptI4tbo",
  "-4_ApAo9BEE",
  "915-OByzH-g",
  "akWn_WD2cyA",
  "nN6gFQMr3yU",
  "1DJd0bgGfRs",
  "lg1Rwddms7U",
];
