import * as React from "react";

import { Content } from "../components/Content";
import { Header } from "../components/Header";
import { Hero } from "../components/Hero";
import { Theme } from "../components/Theme";
import { Box, Typography } from "@material-ui/core";
import { Theme as MuiTheme, makeStyles } from "@material-ui/core";
import { videos } from "../data/videos";

const useStyles = makeStyles((theme: MuiTheme) => ({
  container: {
    color: "#FFF",
    marginBottom: 48,
    marginTop: 24,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  video: {
    margin: theme.spacing(1),

    img: {
      width: "100%",
      display: "block",
    },
  },
}));

const VideoPage = () => {
  const classes = useStyles();

  return (
    <Theme>
      <main>
        <title>Video - Thenable</title>
        <Header />
        <Hero />
        <Content>
          <Typography variant="h4">Video</Typography>
          <Box className={classes.container}>
            {videos.map((id) => (
              <a
                target="_blank"
                href={`https://www.youtube.com/watch?v=${id}`}
                key={id}
                className={classes.video}
              >
                <img src={`https://img.youtube.com/vi/${id}/mqdefault.jpg`} />
              </a>
            ))}
          </Box>
        </Content>
      </main>
    </Theme>
  );
};

export default VideoPage;
